import React from "react";
//import "../css/Privacy.css";
import Footer from "../Footer/Footer";

export default function Privacy() {
  return (
    <>
     <div style={{ lineHeight: '2.5' }}id="privacy-policy">
      <section id="skills">
        <div className="container">
          <p>
            <h1 style={{fontSize: "24px", fontWeight: "bold"}}>PRIVACY POLICY </h1>     
          </p>

          <p>
              <strong><u>This is the privacy notice of IYKONS Limited and other members of the IYKONS Group.</u></strong>
          </p>

          <p>            
          We respect your privacy and are determined to
              protect your personal data. The purpose of this privacy notice is
              to inform you as to how we look after your personal data when you
              visit our website (regardless of where you visit it from). We’ll
              also tell you about your privacy rights and how the <a href="https://www.gov.uk/government/publications/data-protection-act-2018-overview"> United Kingdom
              Data Protection Act of 2018</a> protects you. You agree with all
              matters relating to access or use of our website, including all
              disputes, will be governed by English law. This Privacy Policy
              does not apply to personal information that we collect about
              IYKONS employees and other personnel, contractors, or applicants
              and candidates. This privacy notice is provided in a layered
              format so you can click through to the specific areas set out
              below. Alternatively you can download a pdf version of the policy <a href="https://web.archive.org/web/20220625024142/https://www.iykons.com/Document/IYKONS_Privacy_Policy.pdf">
               Click Here
            </a>
          </p>


          <ol style={{fontWeight: "bold"}}>
            <li>WHO WE ARE AND IMPORTANT INFORMATION</li>
            <li>THE PERSONAL DATA WE COLLECT ABOUT YOU</li>
            <li>HOW WE COLLECT YOUR PERSONAL DATA</li>
            <li>HOW WE USE YOUR PERSONAL DATA</li>
            <li>WHO WE SHARE YOUR PERSONAL DATA WITH</li>
            <li>INTERNATIONAL TRANSFERS</li>
            <li>DATA SECURITY</li>
            <li>DATA RETENTION</li>
            <li>YOUR LEGAL RIGHTS</li>
            <li>CHANGES TO THIS NOTICE AND YOUR DUTY TO INFORM US OF CHANGES</li>
            <li>QUERIES, REQUESTS OR CONCERNS</li>
            <li>CHILDREN'S PRIVACY</li>

          </ol>
             <br/>  
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>1.WHO WE ARE AND IMPORTANT INFORMATION</h1>
          <p>
              <strong><u>What is the purpose of this privacy notice?</u></strong>
          </p>
          <p>
          This privacy notice aims to give you information on how we collect
              and process your personal data through your use of this website,
              including any data you may provide through this website when you
              SIGN UP TO RECEIVE OUR NEWSLETTER/ PURCHASE A PRODUCT OR SERVICE/
              REGISTER FOR OUR SERVICES.
          </p>

            <p>
            This website is not intended for children and we do not knowingly
              collect data relating to children.
            </p>

            <p>
            You must read this privacy notice together with any other privacy
              notice we may provide on specific occasions when we are collecting
              or processing personal data about you so that you are fully aware
              of how and why we are using your data. This privacy notice
              supplements the other notices and is not intended to override
              them.
            </p>

        
         <p>
              <strong><u> Data Controller(s)</u></strong>
          </p>

            <p>
            IYKONS LIMITED is the controller and responsible for your personal
              data (collectively referred to as [“COMPANY”], “we”, “us” or “our”
              in this privacy notice).
            </p>

            <p>Our contact details are:</p>

            <p>
              Address: 14 Elm Road, Chessington, Surrey, KT9 1AW, United
              Kingdom.
            </p>

            <p>Email:<a href="mailto:info@iykons.com"> info@iykons.com</a></p>
            <p>Telephone: <a href="tel:+44 20 3598 2904">
+44 20 3598 2904</a></p>

            <p>
              For all data matters contact DATA PROTECTION OFFICER on <a href="mailto:data@iykons.com">
              DATA@IYKONS.COM</a>
            </p>

            <p>
              IYKONS LIMITED is the controller and responsible for this website.
            </p>
       

            <p>
              <strong><u> Third-party links outside of our control</u></strong>
          </p>

            <p>
              This website may include links to third-party websites, plug-ins,
              and applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements.
            </p>
            <p>
              When you leave our website, we encourage you to read the privacy
              notice of every website you visit.
            </p>
          

            <br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>2.THE PERSONAL DATA WE COLLECT ABOUT YOU</h1>

            <p>
              Personal data, or personal information, means any information
              about an individual from which that person can be identified. You
              can find out more about personal data from the <a href="https://ico.org.uk/global/contact-us/">Information
              Commissioner’s Office.</a>
            </p>
            <p>
              We may collect, use, store, and transfer different kinds of
              personal data about you which we have grouped together as follows:
            </p>
            <p>
              <strong>Identity Data</strong> includes FIRST NAME/LAST NAME/
              USERNAME/MARITAL STATUS/ TITLE/DATE OF BIRTH/GENDER.
            </p>
            <p>
              <strong>Contact Data</strong> includes BILLING
              ADDRESS/DELIVERY/POSTAL ADDRESS/EMAIL ADDRESS/ TELEPHONE NUMBERS.
            </p>
            <p>
              
                WE ALSO COLLECT, USE AND SHARE <strong>AGGREGATED DATA</strong> SUCH AS
                STATISTICAL OR DEMOGRAPHIC DATA FOR ANY PURPOSE.AGGREGATED DATA
MAY BE DERIVED FROM YOUR PERSONAL DATA BUT IS NOT CONSIDERED PERSONAL DATA IN LAW AS THIS DATA DOES<strong> NOT</strong>  DIRECTLY OR
INDIRECTLY REVEAL YOUR IDENTITY. FOR EXAMPLE, WE MAY AGGREGATE YOUR USAGE DATA TO CALCULATE THE PERCENTAGE OF USERS
ACCESSING A SPECIFIC WEBSITE FEATURE. HOWEVER, IF WE COMBINE OR CONNECT AGGREGATED DATA WITH YOUR PERSONAL DATA SO THAT IT
CAN DIRECTLY OR INDIRECTLY IDENTIFY YOU, WE TREAT THE COMBINED DATA AS PERSONAL DATA WHICH WILL BE USED IN ACCORDANCE WITH
THIS PRIVACY NOTICE.
            </p>

            <p>
              We do not collect any <strong>Special Categories of Personal Data</strong> about
              you (this includes details about your race or ethnicity, religious
              or philosophical beliefs, sex life, sexual orientation, political
              opinions, trade union membership, information about your health
              and genetic and biometric data). Nor do we collect any information
              about criminal convictions and offences.
            </p>

            <p>
              <strong><u>If you fail to provide personal data</u></strong>
            </p>

            <p>
              Where we need to collect your personal data by law, or under the
              terms of a contract we have with you and you fail to provide that
              data when requested,<strong> we may not be able to perform the contract</strong> we
              have or are trying to enter into with you (for example, to provide
              you with goods or services). In this case, we may have to cancel a
              product or service you have with us but we will notify you if this
              is the case at the time.
            </p>
            <br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>3. How we are collect your personal data</h1>

<p>We use different methods to collect data from and about you including through:</p>
          
           <p><ul>
        <li>
          <strong>Directly.</strong> You may give us your IDENTITY, CONTACT, AND FINANCIAL DATA by filling in forms or by corresponding with us by post, phone, email, or otherwise. This includes personal data you provide when you
          <ul>
            <li>Apply for our products or services;</li>
            <li>Create an account on our website;</li>
            <li>Subscribe to our service or publications;</li>
            <li>Request marketing to be sent to you.</li>
          </ul>
        </li>
      </ul></p>
      <br/>
      <h1 style={{fontSize: "24px", fontWeight: "bold"}}>4. HOW WE USE YOUR PERSONAL DATA</h1> 
            <p>
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>
            <p>
              <strong>Performance of Contract:</strong> This means processing
              your data where it is necessary for the performance of a contract
              to which you are a party or to take steps at your request before
              entering into such a contract.
            </p>
            <p>
              <strong>Legitimate Interest:</strong> This refers to the interest
              of our business in conducting and managing our business to enable
              us to provide you with the best service/product and the most
              secure experience. We ensure we consider and balance any potential
              impact on you (both positive and negative) and your rights before
              processing your personal data for our legitimate interests. We do
              not use your personal data for activities where our interests are
              overridden by the impact on you (unless we have your consent or
              are otherwise required or permitted by law). You can obtain
              further information about how we assess our legitimate interests
              against any potential impact on you by contacting the Data
              Protection Officer.
            </p>
            <p>
              <strong>Comply with a Legal or Regulatory Obligation:</strong>{" "}
              This means processing your personal data where it is necessary for
              compliance with a legal or regulatory obligation that we are
              subject to.
            </p>
            <p>
            GENERALLY WE DO NOT RELY ON CONSENT AS A LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA OTHER THAN IN RELATION TO SENDING
THIRD PARTY DIRECT MARKETING COMMUNICATIONS TO YOU VIA EMAIL OR TEXT MESSAGE. YOU HAVE THE RIGHT TO WITHDRAW CONSENT TO
MARKETING AT ANY TIME BY CONTACTING DATA PROTECTION OFFICER.
            </p>
      

          
            <p>
              <strong>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</strong>
            </p>
            <p>
              We have set out below, in a table format, a description of all the ways we plan to use your personal data, with the legal bases we rely on to do so.
            </p>
            <p>
              Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact DATA PROTECTION OFFICER if you
              need details about the specific legal ground we are relying on to process you personal data where more than one ground has been set out in the table below.
             
            </p>
          

            <table className="" style={{ borderCollapse: "collapse", border: "1px solid black", width: "100%" }}>
        <tbody>
          <tr>
            <th style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>Purpose/Activity</th>
            <th style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>Type of data</th>
            <th style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>Lawful basis for processing including basis of legitimate interest</th>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>To register you as a new customer</td>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>(a) Identity<br />(b) Contact</td>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "center" }}>Performance of a contract</td>
          </tr>
        </tbody>
</table>

<br/>
<p>
              <strong><u>Marketing</u></strong>
          </p>
       <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.</p>
            <p>
                WE HAVE ESTABLISHED THE FOLLOWING PERSONAL DATA CONTROL
                MECHANISMS:
            </p>
          

            <p>
              <strong><u>Promotional offers from us</u></strong>
          </p>
      
         
            <p>
              We may use your Identity, Contact, Technical, Usage and Profile
              Data to form a view on what we think you may want or need, or what
              may be of interest to you. This is how we decide which products,
              services and offers may be relevant for you.
            </p>
            <p>
              You will receive marketing communications from us if you have
              requested information from us or purchased goods or services from
              us and, in each case, you have not opted out of receiving that
              marketing.
            </p>
          
            <p>
              <strong><u>Third-party marketing</u></strong>
          </p>
         
            <p>
                We will get your express opt-in consent before we share your
                personal data with any company outside the IYKONS group of
                companies for marketing purposes.
             
            </p>
         
          <p>
              <strong><u>Opting out</u></strong>
          </p>
          
          
            <p>
            You can ask us or third parties to stop sending you marketing messages at any time BY LOGGING INTO THE WEBSITE AND CHECKING OR UNCHECKING RELEVANT
BOXES TO ADJUST YOUR MARKETING PREFERENCES OR BY FOLLOWING THE OPT-OUT LINKS ON ANY MARKETING MESSAGE SENT TO YOU OR BY
MAILING <a href="mailto:data@iykons.com">DATA@IYKONS.COM </a>
    AT ANY TIME.
            </p>
        
<p>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of A PRODUCT/SERVICE PURCHASE OR OTHER
TRANSACTIONS.</p>

<p>
              <strong><u>Cookies</u></strong>
          </p>
      
                <p>You can set your browser to refuse all or some browser
                cookies, or to alert you when websites set or access cookies. If
                you disable or refuse cookies, please note that some parts of
                this website may become inaccessible or not function properly.
                For more information about the cookies we use, please see
              
                  <a href="https://web.archive.org/web/20220625023954/https:/www.iykons.com/cookie-policy">
                    {" "}
                   click here
                  </a>{" "}
               
              </p>
          


              <p>
              <strong><u>Change of purpose</u></strong>
          </p>
          <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is
compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact the
Data Protection Officer. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
              <p>
                Please note that we may process your personal data without your
                knowledge or consent, in compliance with the above rules, where
                this is required or permitted by law.
              </p>
           

              <br/>

              <h1 style={{fontSize: "24px", fontWeight: "bold"}}>5. WHO WE SHARE YOUR PERSONAL DATA WITH</h1>

            <p>
              We may have to share your personal data with the parties set out
              below for the purposes set out in the table in paragraph 4 above.
            </p>
<ul><li>Internal Third Parties</li></ul>
            <p>
              
              IYKONS GROUP OF COMPANIES
            </p>

            <p>
  <ul>
    <li>External Third Parties Service</li>
  </ul>
  <ul>
    <ul style={{ listStyleType: 'disc' }}>
      <li style={{ paddingLeft: '20px' }}>HM Revenue & Customs (HMRC), National Crime Agency (NCA), regulators and other authorities (ACTING AS PROCESSORS OR JOINT CONTROLLERS) IN THE UNITED KINGDOM WHO REQUIRE REPORTING OF PROCESSING ACTIVITIES IN CERTAIN CIRCUMSTANCES.</li>
    </ul>
  </ul>
</p>




<p>
<ul><li>Third parties to whom we may choose to sell, transfer, or
              merge parts of our business or our assets. Alternatively, we may
              seek to acquire other businesses or merge with them. If a change
              happens to our business, then the new owners may use your personal
              data in the same way as set out in this privacy notice.</li></ul>
              </p>
            

            <p>
              We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law. We do
              not allow our third-party service providers to use your personal
              data for their own purposes and only permit them to process your
              personal data for specified purposes and in accordance with our
              instructions.
            </p>
          
            <br/>
            <h1 style={{fontSize: "24px", fontWeight: "bold"}}>6. INTERNATIONAL TRANSFERS</h1>
          

          <p>WE SHARE YOUR PERSONAL DATA WITHIN THE IYKONS GROUP. THIS WILL INVOLVE TRANSFERRING YOUR DATA OUTSIDE THE UNITED KINGDOM
AND EUROPEAN ECONOMIC AREA <strong>(EEA).</strong></p>
<p>WHENEVER WE TRANSFER YOUR PERSONAL DATA OUT OF THE UK AND EEA, WE ENSURE A SIMILAR DEGREE OF PROTECTION IS AFFORDED TO IT BY
IMPLEMENTING SAFEGUARDS ACCORDING TO UNITED KINGDOM DATA PROTECTION ACT 2018.</p>
<p>PLEASE CONTACT US IF YOU WANT FURTHER INFORMATION ON THE SPECIFIC MECHANISM USED BY US WHEN TRANSFERRING YOUR PERSONAL
DATA OUT OF THE UK AND EEA</p>

<br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>7. DATA SECURITY</h1>




          <p>
          We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In
addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your
personal data on our instructions and they are subject to a duty of confidentiality.
          </p>
<p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do
so.</p>
<br/>    
<h1 style={{fontSize: "24px", fontWeight: "bold"}}>8. DATA RETENTION</h1>

        
      <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or
reporting requirements.</p>
             <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised
use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the
applicable legal requirements.</p>       
<p>DETAILS OF RETENTION PERIODS FOR DIFFERENT ASPECTS OF YOUR PERSONAL DATA ARE AVAILABLE IN OUR RETENTION POLICY WHICH YOU CAN
REQUEST FROM US BY<a href="mailto:data@iykons.com"> DATA@IYKONS.COM</a> </p>

<p>By law we have to keep basic information about our customers (INCLUDING CONTACT, IDENTITY, FINANCIAL AND TRANSACTION DATA) FOR SIX YEARS AFTER
THEY CEASE BEING CUSTOMERS FOR TAX PURPOSES</p>
          <p>In some circumstances you can ask us to delete your data: see <strong>Your legal rights</strong> below for further information.</p>
               
            <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this
information indefinitely without further notice to you.</p>   

<br/>               
<h1 style={{fontSize: "24px", fontWeight: "bold"}}>9. YOUR LEGAL RIGHTS</h1>

                
            
          <p>
            Unless subject to an exemption under the data protection laws, you
            have the following rights with respect to your personal data:
          </p>
          <ul>
            <li>
              The right to request a copy of the personal data which we hold
              about you;
            </li>
            <li>
              The right to request that we correct any personal data if it is
              found to be inaccurate or out of date;
            </li>
            <li>
              The right to request your personal data is erased where it is no
              longer necessary to retain such data;
            </li>
            <li>
              The right to withdraw your consent to the processing at any time,
              where consent was the lawful basis for processing your data;
            </li>
            <li>
              The right to request that we provide you with your personal data
              and where possible, to transmit that data directly to another data
              controller, (known as the right to data portability), where
              applicable (i.e., where our processing is based on consent or is
              necessary for the performance of our contract with you or where we
              process your data by automated means);
            </li>
            <li>
              The right, where there is a dispute in relation to the accuracy or
              processing of your personal data, to request a restriction is
              placed on further processing;
            </li>
            <li>
              The right to object to our processing of personal data, where
              applicable (i.e., where processing is based on our legitimate
              interests (or in performance of a task in the public
              interest/exercise of official authority); direct marketing or
              processing for the purposes of scientific/historical research and
              statistics).
            </li>
          </ul>
          <p>
          If you wish to exercise any of the rights set out above, please contact DATA PROTECTION OFFICE FOR SUBJECT ACCESS RIGHTS.
          </p>
          <p>
            <strong><u>No fee required – with some exceptions</u></strong>
            <br/>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable admin fee if your request is clearly unfounded,
            repetitive, or excessive. Alternatively, we may refuse to comply
            with your request in these circumstances.
          </p>
          <p>
            <strong><u>What we may need from you</u></strong>
         <br/>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
          </p>
          <p>
            <strong><u>Time limit to respond</u></strong>
            <br/>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
          <br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}> 10. CHANGES TO THIS NOTICE AND YOUR DUTY TO INFORM US OF CHANGES</h1>
          <p>
            <p>
              Please keep us informed if your personal data changes during your
              relationship with us. It is important that the personal data we
              hold about you is accurate and current.We reserve the right to revise this Policy or any part of it from
              time to time. Please review the Policy periodically for changes.
              If we make material changes, we will endeavor to provide notice
              ahead of such changes, such as by email or through a notice on{" "}
              <a href="https://www.iykons.com/privacy">
                https://www.iykons.com/privacy
              </a>  
            </p>
          </p>
          <br/>
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>11. QUERIES, REQUESTS OR CONCERNS</h1>



              <p>
                To exercise all relevant rights, queries, or complaints in
                relation to this policy or any other data protection matter
                between you and us, please in the first instance contact our
                DATA PROTECTION OFFICER at{" "}
                <a href="mailto:data@iykons.com">data@iykons.com</a>.
              </p>
              <p>
                If this does not resolve your complaint to your satisfaction,
                you have the right to complain with the Information
                Commissioners Office on{" "}
                <a href="tel:03031231113">03031231113</a> or via email{" "}
                <a href="https://ico.org.uk/global/contact-us/email/">
                  https://ico.org.uk/global/contact-us/email/
                </a>{" "}
                or at the Information Commissioner’s Office, Wycliffe House,
                Water Lane, Wilmslow, Cheshire, SK9 5AF, England, UK.
              </p>
            

          <br/>  
          <h1 style={{fontSize: "24px", fontWeight: "bold"}}>12.CHILDREN'S PRIVACY</h1>
          <p>We are committed to protecting the privacy of children. Our website, products, and services are not designed or intended for children under the age of 13, and we do not knowingly collect personal data from children under 13. If we discover that we have inadvertently collected personal data from a child under 13, we will take immediate steps to delete that information from our systems.</p>
          
          <p>
              <strong><u>Parental Consent:</u></strong>
          </p>
          <p>
          In cases where services or sections of our website may be relevant to children, we require parental consent before collecting any personal data. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact our Data Protection Officer at <a href="mailto:data@iykons.com">data@iykons.com</a> to request the removal of such information.
          </p>

          <p>
              <strong><u>Third-Party Links and Services:</u></strong>
          </p>
          <p>We do not knowingly allow third-party services that are targeted at or designed for children to collect personal data through our website. We encourage parents to monitor their children's online activities and to instruct them never to provide personal information without permission.
            </p>

            <p>
            For more information on how we ensure compliance with children’s privacy laws, or to request the deletion of your child’s personal data, please contact us at <a href="mailto:data@iykons.com">data@iykons.com</a>.
            </p>

        </div>
      </section>

      <section id="servicesj">
        <div className="container">
          <header className="section-header wow fadeInUp">
            <h4></h4>
            <br />
          </header>

          <div className="row"></div>
        </div>
        </section>
      </div>
      <Footer />
    </>
  );
}